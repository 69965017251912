@keyframes wave {

    0%,
    100% {
        transform: translateY(0) rotate(0);
    }

    50% {
        transform: translateY(20px) rotate(5deg);
    }
}

.animate-wave {
    animation: wave 8s infinite alternate ease-in-out;
}

.animate-wave2 {
    animation: wave 10s infinite alternate ease-in-out;
}

.animate-wave3 {
    animation: wave 12s infinite alternate ease-in-out;
}